.cdk-overlay-pane {
  & > .mat-menu-panel {
    max-width: 300px;

    &.notification-list {
      max-width: 435px;
      min-width: 435px;
    }

    &.select-tree {
      max-width: 506px;
      min-width: 506px;
    }
  }
}
