@import 'variables';

.mat-progress-bar {
  height: 8px !important;
  border-radius: 3px;

  .mat-progress-bar-fill {
    border-radius: 3px;
  }

  .mat-progress-bar-fill::after {
    border-radius: 3px 0px 0px 3px;
    background: linear-gradient(270deg, rgba(78, 176, 141, 0.2) -4.22%, rgba(78, 176, 141, 0.028) 104.79%),
      linear-gradient(
        273.43deg,
        rgba(78, 176, 141, 0.88) 1.79%,
        rgba(78, 176, 141, 0.25) 26.01%,
        rgba(78, 176, 141, 0) 111.2%
      ),
      linear-gradient(270deg, rgba(78, 176, 141, 0.6) 1.37%, rgba(78, 176, 141, 0.13) 102%);
    border-right: 2px solid $tpp-white;
  }

  &.warn .mat-progress-bar-fill::after {
    background-color: $tpp-input-border-warning;
  }

  &.error .mat-progress-bar-fill::after {
    background: none;
    background-color: $tpp-input-border-error;
  }

  .mat-progress-bar-buffer {
    background-color: $tpp-gray2;
  }
}
