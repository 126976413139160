.w-auto {
  width: auto !important;
}

.w-25 {
  width: 25%;
}

.w-33 {
  width: 33%;
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100% !important;
}

.mw-100 {
  max-width: 100%;
}

.wp-95 {
  width: 95px;
}

.wp-80 {
  width: 80px !important;
}

.wp-100 {
  width: 100px !important;
}

.wp-160 {
  width: 160px !important;
}

.wp-200 {
  width: 200px !important;
}

.wp-220 {
  width: 220px !important;
}

.h-50 {
  height: 50%;
}

.h-100 {
  height: 100%;
}

.h-auto {
  height: auto;
}

.aspect-square {
  aspect-ratio: 1/1;
}
