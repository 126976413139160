$bb-cell-width: 100px;
$bb-cell-half-width: 70px;
$bb-cell-height: 55px;
$bb-menu-width: 170px;

@mixin board-cell() {
  border-radius: 4px;
  width: 100%;
  height: $bb-cell-height;
}

@mixin value-board-cell() {
  flex: auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  background-color: var(--cell-color);
  border: 1px solid $tpp-gray2;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

@mixin empty-board-cell() {
  border-radius: 4px;
  width: 100%;
  height: $bb-cell-height;
  display: flex;
  flex: auto;
  justify-content: center;
  align-items: center;
  border: 1px dashed $tpp-gray7;
  box-sizing: border-box;
  background-color: var(--cell-color);
}

@mixin hoverable-board-cell() {
  transition-duration: 0.2s;
  &:hover {
    border-color: $tpp-white;
    border-style: solid;
    background-color: var(--cell-color);
    box-shadow: 1px 1px 1px rgba(72, 72, 72, 0.12);
    border-radius: 4px;
  }
}

@mixin clickable-board-cell() {
  transition-duration: 0.2s;
  &:active,
  &.active {
    border-color: $tpp-primary-light;
    border-style: solid;
    background-color: var(--cell-color);
    box-shadow: none;
    border-radius: 4px;
  }
}
