@import 'variables';

@mixin modalContainer() {
  .modal-header {
    border-bottom: 2px solid $tpp-gray2;
    padding: 22px 33px 8px 33px;
    margin-bottom: 20px;
  }

  .mat-dialog-content {
    margin: 0;
    padding: 0 33px;
    overflow: scroll;
  }

  .mat-dialog-container {
    padding: 0;
  }

  .mat-dialog-actions {
    padding: 20px 33px 0 33px;
    margin-bottom: 40px;
  }
}

.cdk-drag-handle {
  cursor: move;
}

.schedule-modal-container > mat-dialog-container {
  overflow: visible;
}
