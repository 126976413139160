@import '/src/app/sass/_fonts.mixins';
@import '/_variables';

button {
  @include systemFont();
  height: 40px;
  font-size: 16px;

  .mat-button-wrapper {
    letter-spacing: 0.05em;
  }

  &.dropdown-control-button {
    height: 32px;
    line-height: 30px;
  }

  &.edit {
    width: 45px;
  }

  &.save {
    width: 64px;
  }

  &.delete {
    width: 64px;
  }

  &.turnover-save {
    width: 83px;
  }

  &.turnover-view-history {
    width: 179px;
  }

  &.join {
    font-size: 14px;
    color: $tpp-gray15;
    margin-top: -3px;

    mat-icon {
      transform: rotate(90deg);
    }
  }

  &.new-turnover {
    background-color: $tpp-green2;
    width: 40px;
  }

  &.modal-button {
    height: 28px;
    padding: 0 24px !important;
    line-height: 16px;
  }

  &.step-button {
    height: 38px;
    padding: 0 24px !important;
  }

  &.comment-button {
    height: 20px;
    font-size: 11px !important;
    line-height: 20px;
  }

  &.turnovers-header-button {
    height: 24px;
    line-height: 24px;

    &.only-mine {
      width: 100px;
      height: 32px;
      padding: 5px 8px;
    }

    &.join {
      font-size: 14px;
    }

    &.toggle-all-sections {
      width: 16px;
      height: 16px;
      padding: 0;
      border: 1px solid $tpp-gray2;
      border-radius: 3px;
    }

    &.unit-section {
      .mat-button-wrapper {
        font-size: 24px;
      }
    }
  }

  mat-icon {
    &.remove-icon {
      color: $tpp-failure;
    }

    &.add-icon-large {
      color: map-get($map: $mat-myapp-primary, $key: 700);
      font-size: 2em;
    }
  }

  &.pp-button {
    font-weight: 700;
    padding-left: 27px;
    padding-right: 27px;
    width: 185px;
    height: 40px;

    &.role {
      width: 117px;
    }
  }

  &.back-to-parent {
    width: 119px;
    height: 40px;
  }

  &.mat-icon-button {
    &.btn-attachment {
      width: 24px;
      height: 24px;
    }

    &.button-icon-background {
      width: 22px;
      height: 22px;
      background-color: $tpp-gray10;
    }

    &.button-icon-delete {
      width: 24px;
      height: 24px;
      background-color: $tpp-gray10;
      border-radius: 4px;
    }

    &.button-icon-toggle {
      width: 30px;
      height: 30px;
    }
  }
}

button.mat-stroked-button {
  background-color: $tpp-white;
}

button.mat-stroked-button:not(.mat-button-disabled) {
  border-color: $tpp-gray11;
}

button.mat-button,
button.mat-stroked-button,
button.mat-flat-button {
  min-width: 1%;
  padding: 0px 6px;
  // TODO
  // padding: 8px 24px;

  @include systemFont();
  font-weight: 700;
  border-radius: 5px;

  & mat-icon {
    margin-right: 3px;
  }

  &.thin-button {
    line-height: 30px !important;
  }

  &.extra-thin-button {
    line-height: 22px !important;
  }

  &.modal-close {
    width: 34px;
    height: 34px;
    padding: 0;
  }
}

button.mat-button-base.button-smaller {
  padding: 5px 8px;
  line-height: 125%;
  font-size: 16px;
  height: auto;
}

button {
  &.mat-stroked-button {
    &.mat-primary {
      border-color: $tpp-green2;
      color: $tpp-green2;
    }

    &.mat-error {
      border-color: $tpp-input-border-error;
      color: $tpp-input-border-error;
    }

    &.mat-button-disabled {
      border-color: $tpp-inner-border-default;
    }

    &.reject {
      line-height: 28px;
      height: 32px;
      padding: 0 8px;
      margin: 0;
    }
  }

  &.mat-flat-button {
    &.mat-primary {
      background: $tpp-green2;
    }
  }

  &.mat-raised-button {
    &.mat-primary {
      background: $tpp-green2;
    }

    &.accept {
      line-height: 32px;
      height: 32px;
      padding: 0 8px;
      margin: 0;
    }
  }

  &.mat-fab {
    &.mat-primary {
      background: $tpp-green2;
    }

    &.new-turnover-button,
    &.floating-button {
      height: 60px;
      width: 60px;
      background-color: $tpp-white;
      border: 2px solid $tpp-input-border-focus;
      padding: 0;

      .mat-button-wrapper {
        padding: 0;
      }

      .content {
        height: 56px;
        width: 56px;
      }
    }
  }

  &.mat-button.mat-primary {
    color: $tpp-green2;

    &.mat-button-disabled {
      color: $tpp-gray7;
      cursor: not-allowed;
    }

    &.table-link {
      font-weight: 500;
      padding: 0px;
    }
  }

  &.mat-primary-light {
    background: $tpp-primary-light;
    color: $tpp-white;
  }
}

.mat-warn {
  &:not(.mat-button-disabled) {
    &.mat-stroked-button {
      color: $tpp-red4;
      border: 1px solid $tpp-red4;
    }

    &.mat-raised-button {
      background-color: $tpp-red4;
    }
  }
}

.modal-button {
  height: 28px;
  line-height: 28px;
}

.mat-mini-fab {
  &.sidebar-toggle {
    width: 24px;
    height: 24px;
    background-color: $tpp-white !important;
    line-height: 0;

    .mat-button-wrapper {
      padding: 0 !important;
    }
  }
}
