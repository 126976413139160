@import 'variables';

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.fade-truncate {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  &::before {
    content: ' ';
    width: 100%;
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(90deg, transparent 90%, var(--fade-background-color));
  }
}

.text-size {
  &.s16 {
    font-size: 16px;
    font-weight: 700;
  }

  &.s14 {
    font-size: 14px;
    font-weight: 400;
  }

  &.s12 {
    font-size: 12px;
    font-weight: 400;
  }

  &.s10 {
    font-size: 10px;
    font-weight: 400;
  }
}

.text-color {
  &.error {
    color: $tpp-input-border-error;
  }

  &.red2 {
    color: $tpp-red2;
  }

  &.red3 {
    color: $tpp-red3;
  }

  &.red4 {
    color: $tpp-red4;
  }

  &.red8 {
    color: $tpp-red8;
  }

  &.dark {
    color: $tpp-dark;
  }

  &.orange {
    color: $tpp-orange;
  }

  &.green {
    color: $tpp-green;
  }

  &.primary {
    color: $tpp-green2;
  }

  &.primary-light {
    color: $tpp-primary-light;
  }

  &.primary-dark {
    color: $tpp-primary-dark;
  }

  &.white {
    color: $tpp-white;
  }

  &.gray {
    color: $tpp-gray;
  }

  &.gray3 {
    color: $tpp-gray3;
  }

  &.gray11 {
    color: $tpp-gray11;
  }

  &.gray17 {
    color: $tpp-gray17;
  }

  &.gray6 {
    color: $tpp-gray6;
  }

  &.gray7 {
    color: $tpp-gray7;
  }

  &.gray-medium {
    color: $tpp-gray-medium;
  }
}

.label-caps {
  letter-spacing: 0.05em;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
}

.letter-spacing {
  letter-spacing: 0.05em;
}

.threshold-high {
  color: $threshold-high;
}

.threshold-medium {
  color: $threshold-medium;
}

.threshold-low {
  color: $threshold-low;
}

.line-through {
  text-decoration: line-through;
}
