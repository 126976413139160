textarea {
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
  outline: none;
}

.mat-checkbox {
  .mat-checkbox-inner-container {
    margin-right: 6px;
  }
}
