@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

@mixin systemFont() {
  font-family: 'Lato' !important;
}

@mixin mention() {
  .user-mention {
    border-radius: 3px;
    background-color: $tpp-blue7;
    color: $tpp-blue4;
    padding: 2px;
    display: inline-block;
    margin-right: 2px;

    &.me {
      background-color: $tpp-yellow5;
    }
  }
}

@mixin cellEditableText() {
  @include systemFont();
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.02em;
}

@mixin preTitle() {
  @include systemFont();
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 140%;
  letter-spacing: 0.02em;
}

@mixin bodySmall() {
  @include systemFont();
  font-size: 14px !important;
  font-weight: 400;
  line-height: 19.6px;
}

@mixin bodySmallBold() {
  @include systemFont();
  font-size: 14px;
  font-weight: 700;
  line-height: 19.6px;
}

@mixin customPlaceholder() {
  @include bodySmall();
  color: $tpp-gray11;
}

@mixin bodyDescription() {
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
}

@mixin preLabel() {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
}

@mixin badgesText() {
  @include systemFont();
  font-style: normal;
  font-weight: 700;
  font-size: 8px;
  color: $tpp-white;
}
